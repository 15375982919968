export default {
	CASY: [
		"6:30",
		"6:45",
		"7:00",
		"7:15",
		"7:30",
		"7:45",
		"8:00",
		"8:15",
		"8:30",
		"8:45",
		"9:00",
		"9:15",
		"9:30",
		"9:45",
		"10:00",
		"10:15",
		"10:30",
		"10:45",
		"11:00",
		"11:15",
		"11:30",
		"11:45",
		"12:00",
		"12:15",
		"12:30",
		"12:45",
		"13:00",
		"13:15",
		"13:30",
		"13:45",
		"14:00",
		"14:15",
		"14:30",
		"14:45",
		"15:00",
		"15:15",
		"15:30",
		"15:45",
		"16:00",
		"16:15",
		"16:30",
		"16:45",
		"17:00",
		"17:15",
		"17:30",
		"17:45",
		"18:00",
	],

	OPTIONS: {
		status_auta: [
			"",
			"prišlo",
			"prišlo má čas",
			"je na linke",
			"hotové - nezaplatené",
			"vyplatené",
			"nedostavil sa",
		],
		zakaznik: [
			"AGRART",
			"AGROCOOP",
			"AGROVAT PS",
			"Ando",
			"Angyalka",
			"ARAVER",
			"Arriva",
			"Ato Trans",
			"B&B Team",
			"Baláž",
			"Balogh",
			"Balogh Adrián",
			"Balogh Tomi",
			"Barbieriková",
			"Béreš",
			"Best drive",
			"Bihary",
			"Borka",
			"Botka",
			"Brantner",
			"Brezík",
			"Brezík",
			"Bussines Lease",
			"Camionservis",
			"Cápa",
			"Cargo Expres",
			"Cifra",
			"CITY TAXI",
			"Czuczor",
			"Čala",
			"Danáč Zoli",
			"Delta",
			"Dosyma",
			"Druga",
			"Ešek",
			"Euroexpres Galla",
			"Exatech",
			"Farkaš",
			"Fejes",
			"FERMI",
			"Ficza Peter",
			"Forgáč Peter",
			"Franko",
			"Frido",
			"Gábor Dušan",
			"Gajdoš Jozef",
			"Galbavý",
			"Garai ARAVER",
			"GASTRO DOM",
			"Gažúr",
			"Gogh",
			"Gondola",
			"Grosse Vehne",
			"Gubo",
			"H+H Trans",
			"Habrman",
			"Halás",
			"Halás Erik",
			"Hegedúš Ďurko",
			"Horváthová",
			"Hyundai",
			"Illés Erik",
			"Illy Trans",
			"JOVI TRANS",
			"JT Auto",
			"Juhás",
			"Kačkovič",
			"Kajan",
			"Kálazdi",
			"Kálazi",
			"Karas Tibor",
			"Katler",
			"Kele",
			"Keresztes",
			"Kiss Trans",
			"Klevitrans",
			"Koči",
			"Kočiš Viktor",
			"Komjatice",
			"Kompas",
			"Kostyál",
			"Kóša",
			"Kováč",
			"Krásny",
			"Križan",
			"Kučera",
			"Kucharovič",
			"KUZET",
			"Lábadi Peter",
			"Lajo Trans",
			"Lakatoš",
			"LB Trans",
			"Lease Plan",
			"Levický",
			"Liad",
			"Limestone",
			"Lovász",
			"Lubušký",
			"Lyžica",
			"M Logistika",
			"M+Z Trans",
			"Maci",
			"Máčai",
			"Madarász",
			"Majerčík",
			"Major",
			"Mandík DPD",
			"Mikle",
			"Moto Duo",
			"MST Europe",
			"Nagy Trans",
			"Nap",
			"Németh",
			"Odstrčil",
			"OK TAXI",
			"Onyx",
			"Oslanec",
			"OST s.r.o",
			"OÚ Palárikovo",
			"Ozorai",
			"Pálinkáš",
			"Paremax",
			"Paulík",
			"PD DVORY",
			"PD Ohaj",
			"PD Podhájska",
			"Peternai",
			"Pohrebníctvo Molnár",
			"Poláček",
			"Polák",
			"Poľno Sme",
			"Poľnohospodár",
			"Porsche",
			"Procházka Michal",
			"Prokopec",
			"Prospect",
			"Quick Trans",
			"Richmond",
			"RV Star",
			"Sedlár",
			"SH Tuning",
			"Sivák",
			"Solár",
			"Speedy Servis",
			"Star Cars",
			"Súdovský",
			"SZabó",
			"Šalgó",
			"Šimon",
			"Štefánik",
			"Tamper",
			"Tankers Varga",
			"Teplan",
			"Terravia",
			"Titka",
			"Tomat",
			"Tomata",
			"Tonka",
			"TOPAMA",
			"Tóth",
			"Tóth Ladislav",
			"Tóth Martin",
			"TPD transport",
			"Tuhársky",
			"Urin",
			"Valovič Martin",
			"Varga Dubník",
			"Varga Zsolt",
			"Velt Auto",
			"Vince s.r.o.",
			"Vladap",
			"Vodárne",
			"Volšíková",
			"Volvo",
			"Vrábel",
			"Yssel logistic",
			"Zacharides",
			"Zálešák",
		],
		zakaznik_kategoria: ["", "U", "T", "V", "I"],
		typ_kontroly: [
			"TK",
			"EK",
			"TK + EK",
			"TK opak",
			"EK opak",
			"TK opak + EK opak",
			"Admin",
		],
		platba: ["", "H", "K", "FA"],
		vysledok_tk: ["", "S", "D", "N"],
		vysledok_ek: ["", "S", "D", "N"],
	},

	STATUS_COLOR: {
		"": "row-default",
		prišlo: "row-prislo",
		"prišlo má čas": "row-ma-cas",
		"je na linke": "row-na-linke",
		"hotové - nezaplatené": "row-hotove",
		vyplatené: "row-vyplatene",
		"nedostavil sa": "row-nedostavil",
	},

	STATUS_COLOR_NEW: {
		"": "#ffffff",
		prišlo: "#1cc88a",
		"prišlo má čas": "#36b9cc",
		"je na linke": "#f6c23e",
		"hotové - nezaplatené": "#f57f7f",
		vyplatené: "#d6d6d6",
		"nedostavil sa": "#b881fc",
		refresh: "#ffff00",
	},
}
